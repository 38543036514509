(function($) {

    var RENDER_PATH = '/search-render/';
    var RENDER_MODAL = '#render-modal';
    var RENDER_MODAL_CONTENT = '#render-modal-content';
    var RENDER_IFRAME = '#render-iframe';

    var RenderModal = function(exchanger) {
        var self = this;

        var $modal = $(RENDER_MODAL);
        var $modalContent = $(RENDER_MODAL_CONTENT);

        var redirectIf401 = function(jqXHR) {
            if (jqXHR.status == 401) {
                var loginURL = jqXHR.responseJSON.redirect_to;
                window.location.href = loginURL;
            }
        }

        $('.submit', $modal).on('click', function() {
            var form = $('form', $modalContent);

            $.ajax({
                url: RENDER_PATH,
                type: "POST",
                data: $.param(form.serializeArray())
            }).done(function(data, _, jqxhr) {
                window.location.href = data;
            }).fail(function(jqXHR, statusText) {
                redirectIf401(jqXHR);
            });
        });

        self.params = {};

        self.load = function() {
            // Prepare container for ajax load

            // Load modal from AJAX
            self.params.exchanger = exchanger;
            var xhr = $.ajax({
                type: "GET",
                url: RENDER_PATH,
                data: self.params
            }).fail(function(jqXHR, statusText) {
                redirectIf401(jqXHR);
            }).done(function(data) {
                $modalContent.empty();
                $modalContent.append(data);

                $modal.modal('show');
            });

            return xhr;
        };

        self.set = function(k, v) {
            self.params[k] = v;
        };
    }

    var DimResultToPdf = function() {
        var self = this;

        this.on('click', function() {
            var exchanger = self.data('exchanger');

            var renderModal = new RenderModal(exchanger);

            // Set render fields
            var fields = ['fluid', 'tin', 'tout', 'tamb', 'dp', 'flowrate', 'dissipated-power', 'is-autonomous'];
            for (var i = 0; i < fields.length; i++) {
                var key = fields[i];
                var value = self.data(key);
                var modalkey = key.replace('-', '_');

                renderModal.set(modalkey, value);
            }

            renderModal.load();
        });
    }

    $.fn.DimResultToPdf = function() {
        var args = arguments;
        this.each(function() {
            DimResultToPdf.apply($(this), args);
        });
    };

    $.fn.DimPdfRenderModal = function() {
        var self = this;
        var form = this.find('form');

        this.find('.render-form-submit').on('click', function() {
            form.submit();
        });

        var getOrSetField = function(id) {
            return function() {
                var field = form.find('#render_' + id);
                if (arguments.length == 0) {
                    return field.val();
                }

                return field.val(arguments[0]);
            }
        }
        
        return {
            modal: function() {
                return $.fn.modal.apply(self, arguments);
            },
            reset: function() {
                form[0].reset();
            },

            // fields
            exchanger: getOrSetField('exchanger'),
            dissipated_power: getOrSetField('dissipated_power'),
            tin: getOrSetField('tin'),
            tout: getOrSetField('tout'),
            tamb: getOrSetField('tamb'),
            flowrate: getOrSetField('flowrate'),
            dp: getOrSetField('dp'),
            fluid: getOrSetField('fluid'),
        };
    }



})(jQuery);
