(function($) {

  var doChart = function($el, points, _options) {

    var options = $.extend({
      canvas: true,
      x: {
        min: null, max: null, label: "x",
      },
      y: {
        min: null, max: null, label: "y",
      },
    }, _options);

    var plotOptions = [
      {
        data: points,
        color: 2,
      }];
    var chartOptions = {
      xaxis: {
        ticks: 10,
        autoscaleMargin: 0.05,
        min: options.x.min,
        max: options.x.max,
        tickFormatter: function(val, axis) { return val < axis.datamax ? val.toFixed(0) : options.x.label; },
        font: {
          size: 13,
          color: "#555",
          family: "Noto Sans",
        },
      },
      yaxis: {
        autoscaleMargin: 0.05,
        min: options.y.min,
        max: options.y.max,
        tickFormatter: function(val, axis) { return val < axis.max ? val.toFixed(3) : options.y.label; },
        font: {
          size: 13,
          color: "#555",
          family: "Noto Sans",
        },

      },
      grid: {
        hoverable: true,
        margin: 0,
      },
    };

    if (options.highlight) {
      plotOptions.push({
        data: [options.highlight],
        color: 4,
        points: {
          show: true,
          symbol: "diamond",
          radius: 4,
        },
      });
    }

    // Render chart
    $.plot($el, plotOptions, chartOptions);

    // Create tooltip
    var $tooltip = $("<div class='tooltip'></div>").css({
      position: "absolute",
      display: "block",
      padding: "4px",
      "background-color": "transparent",
      opacity: 0.80,
      bottom: "23px",
      right: "20px",
      "font-weight": "bold",
      "font-size": "13pt",
    }).appendTo($el);

    // Enable tooltip on hover
    $el.bind("plothover", function(event, pos, item) {
      if (item) {
        var x = item.datapoint[0].toFixed(0),
          y = item.datapoint[1].toFixed(2);

        $tooltip.html(x + " " + options.x.label + " = " + y + " " + options.y.label).show();
      } else {
        $tooltip.hide();
      }
    });
  }
  var parsePoint = function(rawPoint) {
    return rawPoint.split(";").map(parseFloat);
  }

  var parsePoints = function(rawPoints) {
    var rawPointsAry = rawPoints.split("|");
    var points = [];

    for (var i = 0; i < rawPointsAry.length; i++) {
      points.push(parsePoint(rawPointsAry[i]));
    }

    return points;
  }

  var DimChart = function(xlabel, ylabel) {
    var self = this;
    var points = [];

    var ADDITIONAL_XRANGE = 5; // in percent

    var init = function() {
      var rawPoints = self.data('points');
      var rawHighlight = self.data('highlight');

      var points = parsePoints(rawPoints);
      var highlight = parsePoint(rawHighlight);

      var xrange = [null, null];
      var xbounds = [null, null];

      var xlabel = self.data('x-uom');
      var ylabel = self.data('y-uom');

      if (points.length > 2) {
        xbounds[0] = points[0][0];
        xbounds[1] = points[points.length - 1][0];

        var delta = (xbounds[1] - xbounds[0]) / 100;
        xrange[0] = xbounds[0] - delta * ADDITIONAL_XRANGE;
        xrange[1] = xbounds[1] + delta * ADDITIONAL_XRANGE;
      }

      doChart(self, points, {
        x: {
          label: xlabel,
          min: xrange[0],
          max: xrange[1],
          bounds: xbounds,
        },
        y: {
          label: ylabel,
        },
        highlight: highlight,
      });
    }

    init();
  }

  $.fn.DimPerformanceChart = function() {
    var args = arguments;
    this.each(function() {
      DimChart.call($(this));
    });
  };

  $.fn.DimPressureChart = function() {
    var args = arguments;
    this.each(function() {
      DimChart.call($(this));
    });
  };

})(jQuery);
