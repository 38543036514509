/*global jQuery*/

(function($) {
    $(function() {
        $('.to-selectize').selectize({
            plugins: ['remove_button'],
            searchField: ['optgroup', 'text']
        });
    });
})(jQuery);
