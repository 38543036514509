(function($) {

    var DimTabs = function(action) {
        var self = this;
        var tabLinks = this.find('li > a');
        var tabsContainer = this.siblings('.dim-tabs-container');
        var SELECTED_CLASS = 'pure-menu-selected';

        // Extract the tab id from the link.
        // i.e. when passed a <a href="#tab1">, this function returns tab1
        // returns null if the href is not an anchor
        var idFromTabLink = function(link) {
            var href = link.attr('href');
            if (!href || href[0] != "#") {
                return null;
            }
            return href.slice(1);
        }

        var tabLinkFromID = function(id) {
            var link = tabLinks.filter('[href="' + id + '"]');
            if (link.length < 1) {
                return null;
            }
            return link.first();
        }

        // Returns the selected tab link, null if none is selected
        var selectedTabLink = function() {
            var selected = tabLinks.parent().filter('.' + SELECTED_CLASS);
            if (selected.length < 1) {
                return null;
            }
            return selected.children('a').first();
        }

        // Returns the ID of the selected tab link, null if none is selected
        var selectedID = function() {
            var selected = selectedTabLink();
            if (!selected) {
                return null;
            }
            return idFromTabLink(selected);
        }

        var switchToLink = function(newLink, force) {
            if (!newLink) {
                return;
            }

            var newID = idFromTabLink(newLink);
            var currentlySelectedLink = selectedTabLink();
            var currentlySelectedID = idFromTabLink(currentlySelectedLink);

            if (newID == currentlySelectedID && !force) {
                return;
            }

            // Deactivate current link
            if (currentlySelectedLink) {
                currentlySelectedLink.parent().removeClass(SELECTED_CLASS);
                tabsContainer.children().filter('#' + currentlySelectedID).slideUp('fast');
            }

            // Activate this link
            newLink.parent().addClass(SELECTED_CLASS);
            tabsContainer.children().filter('#' + newID).slideDown('fast');
        };

        var init = function() {
            // Hide all tabs containers
            tabsContainer.children('div').hide();

            // Activate default tab
            var selected = selectedTabLink();
            if (selected) {
                switchToLink(selected, true);
            }

            // Bind click events on tabs
            tabLinks.on('click', function(e) {
                e.preventDefault();
                switchToLink($(this));
            });
        };

        if (!action) {
            init();
        }

        return this;
    }

    $.fn.DimTabs = function() {
        var args = arguments;
        this.each(function() {
            DimTabs.apply($(this), args);
        });
    }

    /*
    $(function() {
        $('.dim-tabs').DimTabs();
    });
    */

})(jQuery);
