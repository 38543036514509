/*global Ladda, jQuery */

(function($) {

    $.fn.DimSearchResult = function (action) {
        var self = this;
        var SELECTED_CLASS = 'search-result-selected';
        var detail = this.next('.search-result-detail');

        if (detail.length != 1) {
            return;
        };

        var init = function() {
            self.on('click', function() {
                if (isOpen()) {
                    close();
                } else {
                    open();
                }
            });
        };

        var isOpen = function() {
            return self.hasClass(SELECTED_CLASS);
        };

        var close = function() {
            self.removeClass(SELECTED_CLASS);
            detail.hide();
            self.trigger('search-result-close', detail);
        };

        var open = function() {
            self.addClass(SELECTED_CLASS);
            detail.show();
            self.trigger('search-result-open', detail);

            // Scroll to result
            $('html, body').animate({
                scrollTop: self.offset().top - 5
            }, 200);
        };

        if (action === 'open') {
            open();
        } else if (action === 'close') {
            close();
        } else if (!action) {
            init();
        }

        return this;
    };

    $.fn.DimSearchResultList = function(options) {
        var self = this;
        var results = self.find('.search-result');

        results.each(function() {
            var $result = $(this);
            $result.DimSearchResult();
        });

        // Close other search results when one is opened
        self.on('search-result-open', function(e) {
            results.filter(function() {
                return this != e.target;
            })
            .each(function() {
                $(this).DimSearchResult('close');
            });
        });

        return this;
    };

    $.fn.DimSearchType = function() {
        var self = this;
        
        self.tabs = self.find('li');
        self.form = $('.search-form');
        self.field = $('#id_autonomous_group');

        var showOrHide = function(sel, show) {
            if (show) {
                self.form.find(sel).show();
            } else {
                self.form.find(sel).hide();
            }
        };

        var enableOrDisable = function(sel, enable) {
            if (enable) {
                self.form.find(sel).attr('disabled', false);
            } else {
                self.form.find(sel).attr('disabled', true);
            }
        };

        var updateUnknown = function() {
            var toutCheck = $('#id_search_0');
            var powerCheck = $('#id_search_1');

            var toutField = $('#input-tout');
            var powerField = $('#input-power');

            if (toutCheck.is(':checked')) {
                powerField.hide();
                toutField.show();
            } else {
                toutField.hide();
                powerField.show();
            }
        };

        var updateTinMax = function(isAutonomous) {
            console.log('updateTinMax');
            var $tin = $('#id_tin');
            if (isAutonomous) {
                $tin.attr('max', 93);
            } else {
                $tin.attr('max', 120);
            }
        }

        self.setAutonomous = function(isAutonomous) {
            showOrHide('.search-form-options', !isAutonomous);
            showOrHide('.search-form-flowrate', !isAutonomous);
            showOrHide('.search-form-pressureloss', !isAutonomous);
            showOrHide('.search-form-power-supply', !isAutonomous);
            showOrHide('.search-unknown', !isAutonomous);

            showOrHide('.search-model-exchanger', !isAutonomous);
            showOrHide('.search-model-autonomous', isAutonomous);

            // Ste, riesci sui gruppi autonomi ad escludere la scelta dell'olio iso vg 460?
            // 05/05/2017 08:56
            enableOrDisable('#id_fluid option:contains("VG 460")', !isAutonomous);

            self.field.val(isAutonomous ? 1 : 0);

            var oldTab, newTab;

            if (isAutonomous) {
                oldTab = self.tabs.filter('.search-type-exchangers');
                newTab = self.tabs.filter('.search-type-autonomous-units');
                $('#id_search_1').click();
            } else {
                oldTab = self.tabs.filter('.search-type-autonomous-units');
                newTab = self.tabs.filter('.search-type-exchangers');
            }

            updateUnknown();
            updateTinMax(isAutonomous);

            oldTab.removeClass('search-type-selected');
            newTab.addClass('search-type-selected');
        };

        self.findActiveTab = function() {
            return self.tabs.filter('.search-type-selected');
        };

        self.tabs.on('click', function() {
            var isAutonomous = $(this).hasClass('search-type-autonomous-units');
            self.setAutonomous(isAutonomous);
        });

        self.setAutonomous(self.field.val() == 1);
    };

    
    $(function() {
        // Bind search type tabs
        $('.search-type').DimSearchType();

        // Set Ladda spinner on search button on submit
        Ladda.bind('.search-submit');
    });

})(jQuery);
